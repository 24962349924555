<template>
  <!-- 交易记录 -->
  <div class="bankAccountManagement">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    >
      <el-form-item>
        <el-date-picker
          v-model="daterange"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="getDaterange"
        />
      </el-form-item>
    </FormSearch>
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table
        :item-data="itemData"
        :list-data="listData"
        :loading="loading"
        :operation-width="180"
      />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { summaryGetRealTimeOrder } from '@/api/monitor'
export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      itemData: [
        { label: '订单编号', prop: 'contractNo', width: 180 },
        { label: '业务类型', prop: 'dataSource', width: 120, child: this.$store.getters.getDictionaryItem('business_type') },
        { label: '买家', prop: 'buyerCmpName', width: 180 },
        { label: '卖家', prop: 'sellerCmpName', width: 180 },
        { label: '订单数量（吨）', prop: 'orderTotalWeight', width: 100 },
        { label: '订单金额（元）', prop: 'orderAmount', width: 180 },
        { label: '订单时间', prop: 'orderSignDate', width: 180 }
      ],
      formItemArr: [
        { type: 'select', label: '业务类型', value: 'dataSource', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('business_type') },
        { type: 'input', label: '搜索查询', value: 'keyword' }
      ],
      loading: false,
      total: 0,
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1,
        businessType: '',
        startDate: '',
        endDate: '',
        keyword: ''
      },
      // 日期
      daterange: [],
      editFormVisible: false
    }
  },
  methods: {
    // 获取日期
    getDaterange(arr) {
      if (!arr) {
        this.formInline.startDate = this.formInline.endDate = ''
        return
      }
      this.formInline.startDate = arr[0]
      this.formInline.endDate = arr[1]
    },
    // 获取列表数据
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      if (type === 'reset') {
        this.formInline.pageNum = 1
        this.daterange = []
        this.formInline.startDate = this.formInline.endDate = ''
      }
      summaryGetRealTimeOrder(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    },
    // 查看详情
    orderDetails({ id }) {
      this.$router.push({ path: '/shoppingMall/orderDetails', query: { id }})
    }
  }
}
</script>

<style lang="scss" scoped>
.bankAccountManagement {
  .cmpNameSearch {
    width: 100%;
  }
  .marginTop {
    padding-top: 18px;
  }
}
</style>
